<template>
  <v-app>
    <Header />
    <v-main>
      <router-view />
    </v-main>
    <Footer v-if="showFoot" />
  </v-app>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import { setCookie } from "./utils/cookie";
export default {
  name: "App",
  data() {
    return {
      languageObj: {
        SIMPLIFIED_CHINESE: "简体中文",
        ENGLISH: "ENGLISH",
        TRADITIONAL_CHINESE: "繁體中文",
      },
    };
  },
  components: {
    Header,
    Footer,
  },
  computed: {
    showFoot() {
      if (this.$route.path == "/contract" || this.$route.path == "/delivery") {
        return false;
      }
      return true;
    },
  },
  created() {
    let lang = localStorage.getItem("lang") || "ENGLISH";
    setCookie("lang", lang);
    this.setLanguage(lang);
    this.reloadLangs();
    let rate = localStorage.getItem("rate");
    if (rate) {
      rate = JSON.parse(rate);
      this.setCurrentRate(rate);
    } else {
      this.setCurrentRate({
        code: "USD",
        name: "United States dollar",
        price: 1,
        symbol: "$",
      });
    }
    this.getConfig();
    // this.$vuetify.theme.dark = true;
    this.getSymbolListFuc();
    this.getUserInfoFuc();
    this.getLanguages();
  },
  methods: {
    ...mapActions(["symbolListFetch", "languageFetch", "configFetch"]),
    ...mapMutations([
      "setSymbolList",
      "setLanguage",
      "setCurrentRate",
      "setLanguageList",
      "setConfigData",
    ]),
    
    async getConfig() {
      let res = await this.configFetch();
      if (res.errCode == 0) {
        this.setConfigData(res.data);
        document.title = res.data.systemName
        let favicon = document.querySelector('link[rel="icon"]')
        favicon = document.createElement('link')
        favicon.rel = 'icon'
        favicon.href = res.data.pc2Logo
        document.head.appendChild(favicon)
        
        if(res.data.customerBtnIsHidden && res.data.customerServiceType == 'JS') {
            const scriptMatch = res.data.customerService.match(/<script[^>]*>([\s\S]*?)<\/script>/);
            if (scriptMatch && scriptMatch[1]) {
              const scriptElement = document.createElement('script');
              scriptElement.text = scriptMatch[1];
              document.body.appendChild(scriptElement);
            }
        }
        if(res.data.robotGoogleOpen) {
          const scriptSrc = `https://www.google.com/recaptcha/api.js?render=${res.data.robotGoogleSecret}`
          let script = document.createElement("script")
          script.src = scriptSrc;
          document.body.appendChild(script);
        }
      }
    },
    async getSymbolListFuc() {
      let res = await this.symbolListFetch();
      if (res.errCode == 0) {
        this.setSymbolList(res.data);
      }
    },
    async getLanguages() {
      let res = await this.languageFetch();
      if (res.errCode == 0) {
        let languageList = res.data.map((item) => {
          if (this.languageObj[item.key]) {
            return {
              ...item,
              text: this.languageObj[item.key],
            };
          } else {
            return {
              ...item,
              text: item.name,
            };
          }
        });
        this.setLanguageList(languageList);
      }
    },
  },
};
</script>

<style>
#app {
  /* background: #000000; */
  /* background: rgba(240, 246, 255, 1); */
  background: linear-gradient(#F0F6FF, #FFFFFF);
}
.v-menu__content {
  border-radius: 25px !important;
}
</style>
