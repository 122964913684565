import Vue from 'vue'
import { mapActions, mapMutations, mapGetters } from "vuex"
import { setCookie } from "@/utils/cookie"
Vue.mixin({
    computed: {
        ...mapGetters(["getLanguage", "getLanguageList", "getRateList","getCurrentRate"]),
        rateData() {
            let current = this.getRateList.filter(item => {
                return item.code == this.getCurrentRate.code
            })
            return current && current[0];
        },
    },
    methods: {
        ...mapActions(["userInfoFetch", "userCertificationFetch"]),
        ...mapMutations(["setUserInfo", "setIsLogin", "setAuthInfo","setLanguage"]),
        toFixed(val, decimal = null) {
            if (isNaN(val)) {
                return '--'
            }
            let _decimal = decimal ? decimal : this.getDecimal(val)
            return Number(val).toFixed(_decimal)
        },
        decimalFixed(val, decimal = null) {
            let _decimal = decimal ? decimal : this.getDecimal(val)
            let b = Math.pow(10, _decimal);
            let num = (parseInt(val * b) / b).toFixed(_decimal);
            return num
        },
        getDecimal(value) {
            let val = Math.abs(value)
            if (val == 0) {
                return 0
            } else if (val > 1) {
                return 2;
            } else if (val <= 1 && val > 0.01) {
                return 4;
            } else if (val <= 0.01 && val > 0.001) {
                return 5;
            } else if (val <= 0.001 && val > 0.00001) {
                return 6;
            } else {
                return 8;
            }
        },
        reloadLangs() {
            if (this.getLanguage == "SIMPLIFIED_CHINESE") {
                this.$vuetify.lang.current = "zhHans";
            } else if (this.getLanguage == "TRADITIONAL_CHINESE") {
                this.$vuetify.lang.current = "zhHant";
            } else if (this.getLanguage == "ENGLISH") {
                this.$vuetify.lang.current = "en";
            } else if (this.getLanguage == "SPANISH") {
                this.$vuetify.lang.current = "es";
            } else if (this.getLanguage == "JAPANESE") {
                this.$vuetify.lang.current = "ja";
            } else if (this.getLanguage == "THAI") {
                this.$vuetify.lang.current = "th";
            } else if (this.getLanguage == "KOREAN") {
                this.$vuetify.lang.current = "ko";
            } else if (this.getLanguage == "VIETNAMESE") {
                this.$vuetify.lang.current = "vi";
            } else if (this.getLanguage == "GERMAN") {
                this.$vuetify.lang.current = "de";
            } else if (this.getLanguage == "FRENCH") {
                this.$vuetify.lang.current = "fr";
            } else if (this.getLanguage == "ITALIAN") {
                this.$vuetify.lang.current = "it";
            } else if (this.getLanguage == "RUSSIAN") {
                this.$vuetify.lang.current = "ru";
            } else if (this.getLanguage == "TURKIYE") {
                this.$vuetify.lang.current = "tr";
            } else if (this.getLanguage == "ARAB") {
                this.$vuetify.lang.current = "ar";
            }else if(this.getLanguage == "POLAND") {
                this.$vuetify.lang.current = "pl";
            }else if(this.getLanguage == "BULGARIA") {
                this.$vuetify.lang.current = "bg";
            }else if(this.getLanguage == 'LITHUANIA') {
                this.$vuetify.lang.current = "lt";
            }else if(this.getLanguage == 'INDONESIA'){
                this.$vuetify.lang.current = "id";
            }else if(this.getLanguage == 'PORTUGAL'){
                this.$vuetify.lang.current = "pt";
            }else if(this.getLanguage == 'HUNGARY'){
                this.$vuetify.lang.current = "hu";
            }else {
                this.$vuetify.lang.current = "en";
            }
        },
        langClick(data) {
            console.log(data,"....")
            setCookie("lang", data.key);
            this.setLanguage(data.key);
            localStorage.setItem("lang", data.key);
            this.reloadLangs();
            location.reload();
        },
        numConvert(num, decimal = null) {
            if (num == '--' || num === 0 || isNaN(num) || num == Infinity || num == 'undefined') {
                return 0
            }
            let multiple, unit;
            if (num >= 1000000000000000) {
                multiple = 10000000000000
                unit = 'Q'
            } else if (num >= 1000000000000) {
                multiple = 10000000000
                unit = 'T'
            } else if (num >= 1000000000) {
                multiple = 10000000
                unit = 'B'
            } else if (num >= 1000000) {
                multiple = 10000
                unit = 'M'
            } else {
                multiple = 10
                unit = 'K'
            }
            if (num >= 1000) {
                let strNum = String(num / multiple / 100),
                    sp = strNum.split('.');
                if (sp[1]) {
                    return sp[0] + '.' + sp[1].slice(0, 2) + unit
                }
                return sp + unit
            }
            if (decimal) {
                return this.toFixed(num, decimal)
            }
            return num
        },
        async getUserInfoFuc() {
            let res = await this.userInfoFetch();
            if (res.errCode == 0) {
                this.setUserInfo(res.data);
                this.setIsLogin(true);
                let nickName = res.data.nickName || res.data.email || res.data.phone || res.data.address;
                localStorage.setItem('nickName',nickName)
            }else {
                this.setUserInfo({});
                this.setIsLogin(false);
            }
        },
        async getUserCertification() {
            let res = await this.userCertificationFetch();
            if (res.errCode == 0) {
                this.setAuthInfo(res.data);
            }
        },
        truncateDecimal(number, decimalPlaces) {
            if (isNaN(number)) {
                return "--";
            }
            let _decimalPlaces = decimalPlaces || 2;
            const factor = Math.pow(10, _decimalPlaces);
            return Math.floor(number * factor) / factor;
        },
    }
})