
const urlMode = 'list'; // list 或 default
export { urlMode };
export default [
  'https://api.tofro.me',
  'https://api.tofro.pro',
  'https://api.tofro.lol',
  'https://api.tofro.com',
  'https://api.tofroa.com',
  'https://tof.tofaocfs.com',
]
